<template>
  <div>
    <HeroInner descrition="Gestão de Usuário" class="mt-70" />
    <UserInvite
      ref="userInviteComponent"
      :entityId="selectedEntityId"
      @refresh="loadPeopleInformation"
    />
    <div class="grid-box">
      <v-layout bg-white ma-4 mt-5 column br-4 elevation-base>
        <div class="my-donations-header">
          <div>
            <Icon name="fal fa-users-cog title-icon" size="32" colorFont="#C3C6D5" />
            <h2>Gestão de usuários</h2>
          </div>
          <v-btn
            flat
            round
            color="white"
            class="btn btn-primary px-4"
            large
            @click="openUserInvite"
          >
            <Icon name="far fa-user-plus" size="16" class="mr-2" colorFont="#fff" />Novo Usuário
          </v-btn>
        </div>
        <v-layout>
          <div class="pa-4 text-xs-right">
            <div title="Buscar CPF...">
              <InputSearch type="text" place-holder="Buscar CPF..." v-model="searchCPF" @keyup.enter.native="search"  @search="search"/>
            </div>
          </div>
          <div class="pa-4 text-xs-right">
            <div title="Buscar Email...">
              <InputSearch type="text" place-holder="Buscar Email..." v-model="searchEmail" @keyup.enter.native="search" @search="search"/>
            </div>
          </div>
          <div class="pa-4 text-xs-right">
            <div title="Buscar Nome...">
              <InputSearch type="text" place-holder="Buscar Nome..." v-model="searchName" @keyup.enter.native="search" @search="search"/>
            </div>
          </div>
        </v-layout>
        <div class="gestao-usuarios">
          <template>
            <v-expansion-panel>
              <v-expansion-panel-content v-for="(person,i) in this.people" :key="i">
                <template v-slot:actions>
                  <i class="fal fa-plus"></i>
                </template>
                <template v-slot:header>
                  <v-layout row>
                    <v-flex xs8>
                      <h3 class="ma-0 fw-600">
                        {{ person.name }}
                        <small class="mx-1">{{ cpfFormatter(person.cpf) }}</small>
                      </h3>
                      <small>{{ person.email }}</small>
                    </v-flex>
                    <v-flex xs4 text-xs-right>
                      <button class="btn-line small mr-2" title="Editar" @click="openUserInvite(person)">
                        <i class="fal fa-pencil"></i>
                      </button>
                      <!-- exibir quando o usuario estiver ativo -->
                      <button v-if="true" class="btn-line small" title="Inativar" @click="confirmActivation(person)">
                        <i class="fal fa-ban"></i>
                      </button>
                      <!-- exibir quando o usuario estiver inativo -->
                      <button v-else class="btn-line small c-red" title="Ativar" @click="confirmActivation(person)">
                        <i class="fal fa-ban"></i>
                      </button>
                    </v-flex>
                  </v-layout>
                </template>
                <v-card class="pa-4">
                  <v-card-text class="card-border">
                    <template>
                      <v-expansion-panel>
                        <v-expansion-panel-content v-for="(cart, index) in person.carts" :key="index">
                          <template v-slot:actions>
                            <i class="fal fa-plus"></i>
                          </template>
                          <template v-slot:header>
                            <v-layout row>
                              <v-flex>
                                <h3 class="fw-400">{{ cart.campaign.name }} <small>{{ cart.entity != null ? cart.entity.name : '' }}</small></h3>
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <v-btn flat small color="white" class="btn-gray">Doador</v-btn>
                              </v-flex>
                            </v-layout>
                          </template>
                          <v-card>
                              <v-layout class="list-item">
                                <v-flex>
                                  <i class="fal fa-shopping-cart mr-2 c-red"></i>
                                  <span class="fw-600 c-text-dark">{{ cart.entity != null ? cart.entity.name : '' }}</span>
                                </v-flex>
                                <v-flex xs4 text-xs-right mr-2>Valor: <strong class="mr-1 c-red">R$ {{ cart.totalValue }}</strong></v-flex>
                                <v-flex xs4 text-xs-right mr-2>Status: <strong class="mr-1 c-red">{{ cart.status ? cart.status : '' }}</strong></v-flex>
                                <v-flex xs4 text-xs-right mr-2>Forma de Pagamento: <strong class="mr-1 c-red">{{ cart.payment ? cart.payment.name : '' }}</strong></v-flex>
                                <v-flex xs4 text-xs-right mr-2>Recorrencia: <strong class="mr-1 c-red">{{ cart.recurrence ? cart.recurrence.name : '' }}</strong></v-flex>
                              </v-layout>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </template>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-pagination
              v-model="pagination.page"
              :length="pagination.length"
              :total-visible="7"
              @input="onPageChange"
            ></v-pagination>
          </template>
          <AucAlert
            ref="alertComponent"
            :title="'Deseja realmente ' + (currentItem && currentItem.user.active ? 'inativar' : 'ativar') + ' o usuário?'"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="setRoleActivationStatus(currentItem, !currentItem.user.active)"
          />
        </div>
      </v-layout>
    </div>
  </div>
</template>
<script type="plain/text">
import HeroInner from "@/components/HeroInner.vue";
import PersonService from "@/scripts/services/person.service";
import UserService from "@/scripts/services/user.service";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import UserInvite from "@/views/app/user/modal/UserInvite.vue";
import AucAlert from "@/components/AucAlert.vue";
import UserPerson from "@/scripts/models/userPerson.model";
import {
  EntityType,
  EntityTypeList
} from "@/scripts/models/enums/entityType.enum.js";

export default {
  components: {
    HeroInner,
    UserInvite,
    AucAlert
  },
  data: () => ({
    personService: new PersonService(),
    userService: new UserService(),
    formatterHelper: new FormatterHelper(),
    currentItem: null,
    pagination: {
      sortBy: "name"
    },
    pagination: {
      page: 1,
      length: 1,
      pageSize: 20,
      total: 0
    },
    selectedEntityId: 0,
    searchEmail: "",
    searchCPF: "",
    searchName: "",
    selected: [],
    headers: [
      {
        text: "Nome",
        align: "left",
        value: "name"
      },
      { text: "CPF", align: "left", value: "cpf" },
      { text: "Tipo de entidade", align: "left", value: "entityType" },
      { text: "Perfil", align: "left", value: "userRole.role.name" },
      { text: "Nome da entidade", align: "left", value: "entity.name" },
      {
        text: "Grupo Econômico",
        align: "left",
        value: "company.companyGroup.name"
      },
      { text: "E-mail", align: "left", value: "email" },
      { text: "Telefone", value: "phone" },
      { text: "Gênero", align: "left", value: "gender" },
      { text: "Último login", align: "left", value: "lastLogin" },
      { text: "Data de Nascimento", align: "left", value: "birthDate" },
      { text: "UF", align: "left", value: "address.estate" },
      { text: "Cidade", align: "left", value: "address.city" },
      { text: "CEP Residencial", align: "left", value: "address.postalCode" },
      { text: "Logradouro", align: "left", value: "address.street" },
      { text: "Número", value: "address.number" },
      { text: "Complemento", align: "left", value: "address.complement" },
      { text: "Bairro", align: "left", value: "address.neighborhood" }
    ],
    people: []
  }),
  created() {
    this.loadPeopleInformation();
  },
  methods: {
    search() {
      this.personService.loadPaginationInfo(this.pagination.page, this.pagination.pageSize, this.searchName, this.searchEmail, this.searchCPF).then((result) => {
        this.pagination.page = result.page;
        this.pagination.length = result.length;
        this.pagination.total = result.total;
      });

      this.personService.loadPagedPeopleInformation(this.pagination.page, this.pagination.pageSize, this.searchName, this.searchEmail, this.searchCPF).then(this.onPeopleLoaded);
    },
    cpfFormatter(cpf) {
      return new FormatterHelper().formatCpf(cpf);
    },
    onPageChange() {
      this.personService.loadPagedPeopleInformation(this.pagination.page, this.pagination.pageSize, this.searchName, this.searchEmail, this.searchCPF).then(this.onPeopleLoaded);
    },
    confirmActivation(item) {
      this.currentItem = item;
      this.$refs.alertComponent.open();
    },
    loadPeopleInformation() {
      this.personService.loadPaginationInfo(this.pagination.page, this.pagination.pageSize, this.searchName, this.searchEmail, this.searchCPF).then((result) => {
        this.pagination.page = result.page;
        this.pagination.length = result.length;
        this.pagination.total = result.total;
      });
      
      this.personService.loadPagedPeopleInformation(this.pagination.page, this.pagination.pageSize, this.searchName, this.searchEmail, this.searchCPF).then(this.onPeopleLoaded);
    },
    openUserInvite(person) {
      if (person) {
        if (person.entity) {
          let currentEntityId = person.entity.id;
          this.personService.findByCPF(person.cpf, currentEntityId).then(
            function(userPerson) {
              this.$refs.userInviteComponent.open(userPerson);
            }.bind(this)
          );
        } else {
          let user = new UserPerson();

          user.personId = person.id;
          user.userId = person.userId;
          user.name = person.name;
          user.email = person.email;
          user.cpf = person.cpf;

          this.$refs.userInviteComponent.open(user, EntityType.AUC);
        }
      } else {
        this.$refs.userInviteComponent.open(new UserPerson(), EntityType.AUC);
      }
    },
    setRoleActivationStatus(person, activate) {
      if (person.userRole) {
        this.userService
          .setRoleActivationStatus({
            id: person.userRole.id,
            active: activate
          })
          .then(
            function(data) {
              if (data.success) {
                person.active = activate;
              }
            }.bind(this)
          );
      } else {
        this.userService
          .setRoleActivationStatus({
            userId: person.userId,
            active: activate
          })
          .then(
            function(data) {
              if (data.success) {
                person.active = activate;
              }
            }.bind(this)
          );
      }
    },
    onPeopleLoaded(people) {
      this.people = people;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    getEntityType(entityType) {
      switch (entityType) {
        case EntityType.AUC:
          return "AUC";
        case EntityType.Institution:
          return "Instituição";
        case EntityType.Company:
          return "Empresa";
        default:
          return "-";
      }
    }
  },
  filters: {
    cpf: function(cpf) {
      return new FormatterHelper().formatCpf(cpf);
    },
    date: function(date) {
      return new FormatterHelper().formatDate(date);
    },
    postalCode: function(postalCode) {
      return new FormatterHelper().formatPostalCode(postalCode);
    },
    gender: function(gender) {
      if (gender == "M") {
        return "Masculino";
      } else if (gender == "F") {
        return "Feminino";
      } else if (gender == "O") {
        return "Outros";
      }else if (gender == "-"){
        return "Não informar";
      }

      return gender;
    },
    entityType: function(entityType) {
      switch (entityType) {
        case EntityType.AUC:
          return "AUC";
        case EntityType.Institution:
          return "Instituição";
        case EntityType.Company:
          return "Empresa";
        default:
          return "-";
      }
    }
  }
};
</script>

<style lang="css">
select {
  background: url('data:image/svg+xml;utf8,<svg fill="%23c1c1c1" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')
    no-repeat right 1em center / 24px auto white;
}
.gestao-usuarios .v-expansion-panel__container--active
> .v-expansion-panel__header
.v-expansion-panel__header__icon
i:before {
    content: "\F068";
}
</style>